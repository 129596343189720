<div bsModal #sigmaRespTransModal="bs-modal" class="modal fade" id="sigmaRespTransModal" backdrop="static" keyboard="false"
    tabindex="-1" role="dialog" aria-labelledby="sigmaRespTransModalLabel" aria-hidden="true">
    <form [formGroup]="sigmaRespTransForm" (ngSubmit)="submitModal()">
        <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modal-basic-title">{{tittle}} TELKOMSIGMA RESPONSE TRANSFORMATION</h5>
                    <button type="button" class="close" style="width: 5%;" data-dismiss="modal" aria-label="Close" (click)="hideModal()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <hr>
                <div class="modal-body">
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">service code</label>
                        <div class="col-sm-10">
                            <input type="text" pattern="[0-9]*" class="form-control p-input" placeholder="service code" required
                                id="serviceCode" name="serviceCode" formControlName="serviceCode">
                                <span *ngIf="sigmaRespTransForm.controls.serviceCode.invalid && sigmaRespTransForm.controls.serviceCode.errors && (sigmaRespTransForm.controls.serviceCode.dirty || sigmaRespTransForm .controls.serviceCode.touched)">
                                    <small class="text-danger" *ngIf="sigmaRespTransForm.controls.serviceCode.hasError('pattern')">Hanya diperbolehkan numerik.</small>
                                    <br *ngIf="sigmaRespTransForm.controls.serviceCode.hasError('pattern') && sigmaRespTransForm.controls.serviceCode.hasError('maxlength')">
                                    <small class="text-danger" *ngIf="sigmaRespTransForm.controls.serviceCode.hasError('maxlength')">Maximal 3 digit.</small>
                                </span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">show debug</label>
                        <div class="col-sm-10">
                            <div class="media-body text-end icon-state switch-outline">
                                <label class="switch">
                                    <input type="checkbox" checked="" formControlName="showDebug"><span
                                        class="switch-state bg-primary"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">Enabled</label>
                        <div class="col-sm-10">
                            <div class="media-body text-end icon-state switch-outline">
                                <label class="switch">
                                    <input type="checkbox" checked="" formControlName="enabled"><span
                                        class="switch-state bg-primary"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-success" [disabled]="isLoading|| sigmaRespTransForm.invalid">Simpan</button>&nbsp;
                    <button type="button" class="btn btn-danger" (click)="hideModal()">Keluar</button>
                </div>
            </div>
        </div>
    </form>
</div>



<!--Telkomsigma Http Log Modal -->
<div bsModal #sigmaHttpLogModal="bs-modal" class="modal fade" id="addPath" backdrop="static" keyboard="false" tabindex="-1"
    role="dialog" aria-labelledby="addModalLabel" aria-hidden="true">
    <form [formGroup]="sigmaHttpLogForm">
        <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title " id="addModalLabel">{{tittle}} Telkomsigma Http Log</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hideModal()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">http endpoint</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="http endpoint"
                                id="httpEndpoint" name="httpEndpoint" value="" formControlName="httpEndpoint"
                                [ngClass]="{ 'is-valid' : sigmaHttpLogForm.controls.httpEndpoint.valid , 'is-invalid' : sigmaHttpLogForm.controls.httpEndpoint.invalid }">
                                <span *ngIf="sigmaHttpLogForm.controls.httpEndpoint.invalid && sigmaHttpLogForm.controls.httpEndpoint.errors && (sigmaHttpLogForm.controls.httpEndpoint.dirty || sigmaHttpLogForm.controls.httpEndpoint.touched)">
                                    <small class="text-danger" *ngIf="sigmaHttpLogForm.controls.httpEndpoint.hasError('pattern')">Only letters, numbers, and special characters !@#$%^&*()_+-= are allowed.</small>
                                </span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">method</label>
                        <div class="col-sm-9">
                                <select  class="form-control" type="text"  name="method" formControlName="method">
                                    <option value="POST">POST</option>
                                    <option value="PUT">PUT</option>
                                    <option value="PATCH">PATCH</option>
                                </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">content type</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="content type" id="contentType"
                                name="contentType" value="" formControlName="contentType">
                                <small class="form-text text-muted" id="emailHelp">contoh:
                                    "application/json;charset=utf-8"</small>
                                    <span *ngIf="sigmaHttpLogForm.controls.contentType.invalid && sigmaHttpLogForm.controls.contentType.errors && (sigmaHttpLogForm.controls.contentType.dirty || sigmaHttpLogForm.controls.contentType.touched)">
                                        <small class="text-danger" *ngIf="sigmaHttpLogForm.controls.contentType.hasError('pattern')">Only letters, numbers, and special characters !@#$%^&*()_+-= are allowed.</small>
                                    </span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">timeout</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="timeout" id="timeout"
                                name="timeout" value="" formControlName="timeout">
                                <span *ngIf="sigmaHttpLogForm.controls.timeout.invalid && sigmaHttpLogForm.controls.timeout.errors && (sigmaHttpLogForm.controls.timeout.dirty || sigmaHttpLogForm.controls.timeout.touched)">
                                    <small class="text-danger" *ngIf="sigmaHttpLogForm.controls.timeout.hasError('pattern')"> Only numbers, the characters is not allowed.</small>    
                                </span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">keepalive</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="keepalive" id="keepalive"
                                name="keepalive" value="" formControlName="keepalive">
                                <span *ngIf="sigmaHttpLogForm.controls.keepalive.invalid && sigmaHttpLogForm.controls.keepalive.errors && (sigmaHttpLogForm.controls.keepalive.dirty || sigmaHttpLogForm.controls.keepalive.touched)">
                                    <small class="text-danger" *ngIf="sigmaHttpLogForm.controls.keepalive.hasError('pattern')"> Only numbers, the characters is not allowed.</small>    
                                </span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">retry count</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="retry count" id="retryCount"
                                name="retryCount" value="" formControlName="retryCount">
                                <span *ngIf="sigmaHttpLogForm.controls.retryCount.invalid && sigmaHttpLogForm.controls.retryCount.errors && (sigmaHttpLogForm.controls.retryCount.dirty || sigmaHttpLogForm.controls.retryCount.touched)">
                                    <small class="text-danger" *ngIf="sigmaHttpLogForm.controls.retryCount.hasError('pattern')"> Only numbers, the characters is not allowed.</small>    
                                </span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">queue size</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="queue size" id="queueSize"
                                name="queueSize" value="" formControlName="queueSize">
                                <span *ngIf="sigmaHttpLogForm.controls.queueSize.invalid && sigmaHttpLogForm.controls.queueSize.errors && (sigmaHttpLogForm.controls.queueSize.dirty || sigmaHttpLogForm.controls.queueSize.touched)">
                                    <small class="text-danger" *ngIf="sigmaHttpLogForm.controls.queueSize.hasError('pattern')"> Only numbers, the characters is not allowed.</small>    
                                </span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">flush timeout</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="flush timeout"
                                id="flushTimeout" name="flushTimeout" value="" formControlName="flushTimeout">
                                <span *ngIf="sigmaHttpLogForm.controls.flushTimeout.invalid && sigmaHttpLogForm.controls.flushTimeout.errors && (sigmaHttpLogForm.controls.flushTimeout.dirty || sigmaHttpLogForm.controls.flushTimeout.touched)">
                                    <small class="text-danger" *ngIf="sigmaHttpLogForm.controls.flushTimeout.hasError('pattern')"> Only numbers, the characters is not allowed.</small>    
                                </span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">enabled</label>
                        <div class="col-sm-9 media-body text-end icon-state switch-outline">
                            <label class="switch">
                                <input type="checkbox" checked="" formControlName="enabled"><span
                                    class="switch-state bg-primary"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-sm btn-success " (click)="submitModal()"
                        [disabled]="sigmaHttpLogForm.invalid||isLoading">Simpan</button>
                    <button type="button" class="btn btn-sm btn-danger " (click)="hideModal()">Kembali</button>
                </div>
            </div>
        </div>
    </form>
</div>
<!-- End Telkomsigma Http Log Modal -->


<!--Telkomsigma Http Log Modal -->
<div bsModal #sigmaHmacAuth="bs-modal" class="modal fade" id="addPath" backdrop="static" keyboard="false" tabindex="-1"
    role="dialog" aria-labelledby="addModalLabel" aria-hidden="true">
    <form [formGroup]="sigmaHmacAuthForm">
        <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title " id="addModalLabel">{{tittle}} Telkomsigma Hmac Auth</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hideModal()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">hide credentials</label>
                        <div class="col-sm-9 media-body text-end icon-state switch-outline">
                            <label class="switch">
                                <input type="checkbox" checked="" formControlName="hideCredentials"><span
                                    class="switch-state bg-primary"></span>
                            </label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">header name</label>
                        <div class="col-sm-9">
                            <textarea type="text" class="form-control p-input" placeholder="header name"
                                id="headerName" name="headerName" value="" formControlName="headerName"
                                [ngClass]="{ 'is-valid' : sigmaHmacAuthForm.controls.headerName.valid , 'is-invalid' : sigmaHmacAuthForm.controls.headerName.invalid }"></textarea>
                                <span *ngIf="sigmaHmacAuthForm.controls.headerName.invalid && sigmaHmacAuthForm.controls.headerName.errors && (sigmaHmacAuthForm.controls.headerName.dirty || sigmaHmacAuthForm.controls.headerName.touched)">
                                    <small class="text-danger" *ngIf="sigmaHmacAuthForm.controls.headerName.hasError('pattern')">Only letters, numbers, and special characters !@#$%^&*()_+-= are allowed.</small>
                                </span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">String To Sign</label>
                        <div class="col-sm-9">
                            <textarea type="text" class="form-control p-input" placeholder="String To Sign"
                                id="stringToSign" name="stringToSign" value="" formControlName="stringToSign"
                                [ngClass]="{ 'is-valid' : sigmaHmacAuthForm.controls.stringToSign.valid , 'is-invalid' : sigmaHmacAuthForm.controls.stringToSign.invalid }"></textarea>
                                <span *ngIf="sigmaHmacAuthForm.controls.stringToSign.invalid && sigmaHmacAuthForm.controls.stringToSign.errors && (sigmaHmacAuthForm.controls.stringToSign.dirty || sigmaHmacAuthForm.controls.stringToSign.touched)">
                                    <small class="text-danger" *ngIf="sigmaHmacAuthForm.controls.stringToSign.hasError('pattern')">Only letters, numbers, and special characters !@#$%^&*()_+-= are allowed.</small>
                                </span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Parameters</label>
                        <div class="col-sm-9">
                            <textarea type="text" class="form-control p-input" placeholder="Parameters"
                                id="parameters" name="parameters" value="" formControlName="parameters"
                                [ngClass]="{ 'is-valid' : sigmaHmacAuthForm.controls.parameters.valid , 'is-invalid' : sigmaHmacAuthForm.controls.parameters.invalid }"></textarea>
                                <span *ngIf="sigmaHmacAuthForm.controls.parameters.invalid && sigmaHmacAuthForm.controls.parameters.errors && (sigmaHmacAuthForm.controls.parameters.dirty || sigmaHmacAuthForm.controls.parameters.touched)">
                                    <small class="text-danger" *ngIf="sigmaHmacAuthForm.controls.parameters.hasError('pattern')">Only letters, numbers, and special characters !@#$%^&*()_+-= are allowed.</small>
                                </span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Algorithms</label>
                        <div class="col-sm-9">
                            <select  class="form-control" type="text"  name="algorithms"  id="algorithms" formControlName="algorithms"
                            [ngClass]="{ 'is-valid' : sigmaHmacAuthForm.controls.algorithms.valid , 'is-invalid' : sigmaHmacAuthForm.controls.algorithms.invalid }">
                                    <option value="hmac-sha1">hmac-sha1</option>
                                    <option value="hmac-sha256">hmac-sha256</option>
                                    <option value="hmac-sha384">hmac-sha384</option>
                                    <option value="hmac-sha512">hmac-sha512</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Secret Key</label>
                        <div class="col-sm-9">
                            <textarea type="text" class="form-control p-input" placeholder="Secret Key"
                                id="parameters" name="parameters" value="" formControlName="secretKey"
                                [ngClass]="{ 'is-valid' : sigmaHmacAuthForm.controls.secretKey.valid , 'is-invalid' : sigmaHmacAuthForm.controls.secretKey.invalid }"></textarea>
                                <span *ngIf="sigmaHmacAuthForm.controls.secretKey.invalid && sigmaHmacAuthForm.controls.secretKey.errors && (sigmaHmacAuthForm.controls.secretKey.dirty || sigmaHmacAuthForm.controls.secretKey.touched)">
                                    <small class="text-danger" *ngIf="sigmaHmacAuthForm.controls.secretKey.hasError('pattern')">Only letters, numbers, and special characters !@#$%^&*()_+-= are allowed.</small>
                                </span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Message</label>
                        <div class="col-sm-9">
                            <textarea type="text" class="form-control p-input" placeholder="Message"
                                id="message" name="message" value="" formControlName="message"
                                [ngClass]="{ 'is-valid' : sigmaHmacAuthForm.controls.message.valid , 'is-invalid' : sigmaHmacAuthForm.controls.message.invalid }"></textarea>
                                <span *ngIf="sigmaHmacAuthForm.controls.message.invalid && sigmaHmacAuthForm.controls.message.errors && (sigmaHmacAuthForm.controls.message.dirty || sigmaHmacAuthForm.controls.message.touched)">
                                    <small class="text-danger" *ngIf="sigmaHmacAuthForm.controls.message.hasError('pattern')">Only letters, numbers, and special characters !@#$%^&*()_+-= are allowed.</small>
                                </span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">enabled</label>
                        <div class="col-sm-9 media-body text-end icon-state switch-outline">
                            <label class="switch">
                                <input type="checkbox" checked="" formControlName="enabled"><span
                                    class="switch-state bg-primary"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-sm btn-success " (click)="submitModal()"
                        [disabled]="sigmaHmacAuthForm.invalid||isLoading">Simpan</button>
                    <button type="button" class="btn btn-sm btn-danger " (click)="hideModal()">Kembali</button>
                </div>
            </div>
        </div>
    </form>
</div>
<!-- End Telkomsigma Http Log Modal -->